import detailDefault from '../../detailDefault';

export default class detailacm_salesplan extends detailDefault { // eslint-disable-line
    load(sAction, data) { // eslint-disable-line

    }
    update(sAction, data) {
        if (data.field === 'month') {
            const record = sAction.dataGet(data.prefix+'/id');
            if (record) {
                return;
            }
        }
    }
}
