/**
 *
 * @param amount
 * @param decimalCount
 * @param decimal
 * @param thousands
 */
export default function formatNumber(amount, decimalCount = this.userPreferences.default_currency_significant_digits, decimal = this.userPreferences.dec_sep, thousands = this.userPreferences.num_grp_sep) {
    try {
        amount = amount.toString().replace(/\s/g, '');
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';
        decimal = decimal == null ? '.' : decimal;
        thousands = thousands == null ? ' ' : thousands;

        const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        const j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
    } catch (e) {
    // console.log(e)
        return (decimalCount > 0) ? '0' + decimal + '00' : '0';
    }
}
