/**
 * create a device from product template on order line
 * @param {object} data
 */
export default function ordCreateDevice(data) {
    const params = {
        action: 'ordCreateDevice',
        record: data.id,
    };
    const self = this;

    this.rest.post('customAction', params, function(returnData) {
        if (returnData.status) {
            // Na řádku v objednávkách self.route() způsobovalo že se začli načítat subpanely
            // self.route();
            self.confirm(self.translate('LBL_CREATE_DEVICE_REROUTE_CONFIRM', 'acm_orders_lines'), () => {
                self.href('#detail/acm_machines/' + returnData.message.data.record);
                self.popupHide();
            });
        } else {
            self.alert(returnData.errorMessage.text);
        }
    });
}
