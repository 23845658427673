import detailDefault from '../../detailDefault';

export default class detailUsers extends detailDefault {
    load(sAction, data) {
    }

    update(sAction, data) {
        sAction.dsClear();
        let sendData = null;
        switch (data.field) {
            case 'msgraph_callendar_sync_only_upcoming':
                if(data.value){ // if we are setting sync, we want actually start it!
                    const msgraphCallendarUp = sAction.dataGet(`${data.prefix}/fields/msgraph_callendar_up/value`)
                    if(!(+msgraphCallendarUp)){
                        sAction.dsAdd('set', `${data.prefix}/fields/msgraph_callendar_up/value`, 1);
                        sAction.dsAdd('set', `${data.prefix}/changes/fields/msgraph_callendar_up`, 1);
                        sAction.dsProcess();
                    }
                }
                break;
            case 'user_name':
                sendData = {
                    id: sAction.dataGet(data.prefix + '/id'),
                    user_name: data.value,
                };
                sAction.rest.post('checkUsername', sendData, (returnData) => {
                    if (returnData && returnData.found) {
                        const text = sAction.translate('ERR_USER_NAME_EXISTS_1', 'Users') + ' \'' + data.value + '\' ' +
                            sAction.translate('ERR_USER_NAME_EXISTS_2', 'Users');
                        sAction.error(text);
                        sAction.saveField({
                            name: 'user_name',
                            value: '',
                            way: data.prefix + '/fields/user_name',
                        });
                    }
                });
                break;

            case 'msgraph_tasks_outlook_down':
            case 'msgraph_tasks_outlook_up':
            case 'msgraph_tasks_planner_down':
            case 'msgraph_tasks_planner_up':
                this.handleOffice365TaskSwitches(sAction, data);
                break;
        }
    }

    /**
     * User shouldn't synchronize tasks to Office Planner and to To-do (Outlook) at the same time. On collision, display popup
     *
     * @param {sAction} sAction
     * @param {{}} data
     */
    handleOffice365TaskSwitches(sAction, data) {
        const taskDown = !!parseInt(sAction.dataGet(`${data.prefix}/fields/msgraph_tasks_outlook_down/value`));
        const taskUp = !!parseInt(sAction.dataGet(`${data.prefix}/fields/msgraph_tasks_outlook_up/value`));
        const taskPlannerDown = !!parseInt(sAction.dataGet(`${data.prefix}/fields/msgraph_tasks_planner_down/value`));
        const taskPlannerUp = !!parseInt(sAction.dataGet(`${data.prefix}/fields/msgraph_tasks_planner_up/value`));
        if ((taskDown || taskUp) && (taskPlannerDown || taskPlannerUp)) {
            sAction.error(sAction.translate('LBL_MSGRAPH_TASK_PLANNNER_OUTLOOK_COLLISION', 'Users'));
            sAction.dataSet(`${data.prefix}/fields/${data.field}/value`, data.prevValue);
            sAction.dataSet(`${data.prefix}/changes/fields/${data.field}`, data.prevValue);
        }
    }
}
