/**
 * creates new acm_contract_orders record from acm_orders
 * @param {object} data
 */
export default function createServiceOrder(data) {
    const params = {action: 'createServiceOrder', record: data.id};
    const self = this;

    this.rest.post('customAction', params, function(returnData) {
        if (returnData.status) {
            self.href('#detail/acm_contract_orders/' + returnData.message.data.record);
        } else {
            self.alert(returnData.errorMessage.text);
        }
    });
}
