export default function getFileDownloadLink(params) {
    this.rest.fetchData('getFileDownloadLink', 'GET', {recordID: params.id, module: params.module}).then((data) => {
        if(!data?.data?.downloadLink){
            // this shouldn't happen, everything should be handled in catch, but what if...
            this.error("Missing link");
            return;
        }
        const url = encodeURIComponent(data.data.downloadLink);
        window.open(`${this?.param?.server}/getFileViaLink?downloadLink=${url}`, '_blank');
    }).catch((data) => {
        const translateText = this.translate(data.text);
        this.error(translateText)
    });
}