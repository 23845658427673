import React from 'react';
import PureComponent from '../pure';
import Select from '../formElements/Select';
import sAction from 'sAction';
import propTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default class ListViewLooks extends PureComponent {
    /**
     * selectLook - select look
     * @param {*} e
     */
    selectLook(e) {
        const value = e.target.value;
        if (this.props.prefix === 'view') {
            localStorage.setItem(this.props.module+'_saved_search', value);
        }
        sAction.selectLook(e.target.value, this.props.prefix);
        this.clearSearchContext();
        if (sAction.dataGet(`${this.props.prefix}/selected`).size !== 0 &&
            sAction.dataGet('conf/popup/filePath') !== 'listview/filterChange.js') {
            sAction.dynPopup('listview/filterChange.js', {prefix: this.props.prefix});
        }
    }

    /**
     * clearSearchContext - clear search context
     */
    clearSearchContext() {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + '/modul');
        const view = this.props.prefix.split('/').slice(-1)[0];
        const searchContext = sAction.getStorage('searchContext');

        if (searchContext && searchContext[`${module}_${view}`]) {
            searchContext[`${module}_${view}`] = {
                searchData: [],
            };
        }
        sAction.setStorage('searchContext', searchContext);
    }

    /**
     * copyViewLink() - copy view link to clipboard
     * @param {string} currentView
     * @return {void}
     */
    copyViewLink(currentView) {
        const viewUrl = window.location.href.split('?')[0] + '?view=' + currentView;
        // copy to clipboard
        navigator.clipboard.writeText(viewUrl);
        // show message
        sAction.toast({name: sAction.translate('LBL_VIEW_LINK_COPIED'), description: ' '});
    }

    render() {
        const data = this.props.data;
        const namespace = this.props.namespace;
        const options = [
            {value: ' ', label: sAction.translate('LBL_ALL')},
        ];

        const optionsTmp = [];

        const assignedUser = sAction.dataGet('conf/user/id');// pokud jej ziskam tady, tak rozhodne i na BE
        if (this.props.hasAssignedUser || assignedUser) {
            options.push({value: 'only_my', label: sAction.translate('LBL_ONLY_MY')});
        }
        if (data !== undefined) {
            data.forEach((element) => {
                if (namespace === 'popup' && element.get('category') === 'customReport') {
                    return;
                }
                let elementName = element.get('name');
                // translate name when it starts with LBL_
                if (elementName.indexOf('LBL_') === 0) {
                    elementName = elementName.split(' ')[0];
                    elementName = sAction.translate(elementName);
                }
                optionsTmp.push({value: element.get('id'), label: elementName});
            });
        }

        // TODO: convert to functional component and use memo...
        // sort optionsTmp by label
        optionsTmp.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });

        options.push(...optionsTmp);

        let defValue = ' ';
        if ( this.props.prefix === 'view') {
            const lsVal = localStorage.getItem(this.props.module+'_saved_search');
            if (lsVal) {
                defValue = lsVal;
            }
        } else if (this.props.actSavedSearch) {
            defValue = this.props.actSavedSearch;
        }

        const select = (
            <Select
                id={this.props.namespace + '-listViewLookSelect'}
                value={defValue}
                containerClassName="withBorder"
                key={window.location.hash}
                options={options}
                onChange={(e) => this.selectLook(e)}
            />
        );

        return (
            <div className="listViewLooksContainer ">
                <div className="listViewLooksLabel">
                    {sAction.translate('LBL_LIST_LOOKS')}:
                </div>
                <div className="listViewLooksContent">{select}</div>
                {(defValue && defValue.trim().length > 0 && defValue !== 'only_my') &&
                    <div className="copyViewLinkBtn" onClick={() => this.copyViewLink(defValue)}>
                        <span className="iconfas-link" />
                    </div>
                }
            </div>
        );
    }
}

ListViewLooks.propTypes = {
    prefix: propTypes.string,
    data: ImmutablePropTypes.list,
    module: propTypes.string,
    hasAssignedUser: propTypes.bool,
    actSavedSearch: propTypes.string,
    namespace: propTypes.string,
};
